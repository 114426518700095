#notifications-page {
  padding-top: 40px;
  padding-bottom: 130px;

  h3 {
    margin-bottom: 20px;
  }

  .inner-container {
    max-width: 350px;
    margin: 0 auto;
  }

  .date {
    color: $gray-light;
    font-size: 12px;
    float: right
  }

  .notification {
    border: 1px solid #ccc;
    margin-bottom: 15px;
    padding: 6px 8px;
    box-shadow: 0 1px 5px 0 rgba(black, .2);

    .title {
      font-weight: 600;
    }

    .message {
      margin-top: 10px;
      overflow-wrap: break-word;
    }

    .buttons {
      text-align: right;
      margin-top: 5px;

      .btn {
        padding: 6px 8px;
      }
    }

    &.unread {
      background-color: #f4f4ff;
    }
  }
}
