.help {
  .help-content {
    padding-bottom: 100px;
    font-size: 16px;
    line-height: 1.5;

    .section {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 15px;
      margin-bottom: 45px;

      .section-title {
        color: $brand-primary;
        font-size: 20px;
        font-weight: 600;
      }

      .title {
        margin: 30px 0;
        color: $text-color;
        font-weight: 600;

        a {
          display: block;
          padding-right: 25px;
          position: relative;

          &::after {
            content: $fa-var-chevron-up;
            font-family: FontAwesome;
            color: $brand-primary;
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            font-weight: normal;
          }

          &.collapsed::after {
            content: $fa-var-chevron-down;
          }
        }
      }

      .body {
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 45px;
        color: $text-color;

        strong {
          font-weight: 600;
        }

        ul {
          list-style-type: disc;
        }

        ol {
          margin-bottom: 15px;

          > li {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }

        p {
          margin-bottom: 15px;
        }
      }

      > ul {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
          display: block;
        }
      }

      table {
        font-size: 12px;
        th {
          font-weight: 600;
        }
      }
    }

    .section:last-child {
      margin-bottom: 0;
    }
  }

  .table-contents-outer {
    padding: 0;
  }

  .table-contents {
    @include reset-list;

    border-left: 2px solid $brand-primary;
    margin: 0 15px;

    li {
      display: block;
      padding-left: 47px;

      span {
        display: inline-block;
        margin-left: -47px;
        width: 35px;
        text-align: right;
        margin-right: 12px;
      }
    }

    li + li {
      margin-top: 25px;
    }
  }

  @media (max-width: $screen-sm-max) {
    .table-contents {
      border-left: none;
      margin-bottom: 50px;

      li {
        padding-left: 28px;
      }
    }
  }
}
