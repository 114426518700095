.gc-instructions {
  &___dialog {
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.2px;
    color: #423262;
  }

  &__body {
    padding: 45px;
  }

  &__title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 1em;
  }

  &__warning-title {
    font-size: 15px;
    font-weight: 700;
  }
}
