.product-slider {
  margin-top: 50px;

  &__heading-text {
    color: #626262;
    font-size: 24px;
    font-weight: 300;
    margin: .67em 0;
  }

  &__heading-show-more {
    font-size: 16px;
    padding-top: 8px;
    float: right;
    color: #9e9e9e;
  }

  &__heading-icon {
    position: relative;
    font-size: 22px;
    margin-left: 10px;
    bottom: -2px;
  }

  &__slider {
    margin-top: 15px;
    margin-left: -5px;
    margin-right: -5px;
  }

  .product-card {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
  }

  .slick-dotted {
    margin-bottom: 40px;
  }

  .slick-dots {
    bottom: -35px;
  }

  .slick-track {
    margin: 0;
  }

  .slick-slide {
    outline: none;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;

    &::before {
      display: block;
      transition: .05s linear all;
    }

    &:hover::before {
      transform: scale(1.5);
      color: $brand-primary;
    }
  }

  @media (max-width: $screen-lg-min + 100px) {
    .slick-prev { left: 20px; }
    .slick-next { right: 20px; }
  }
}
