//
// Colors
//

$brand-primary: #ec1c34;
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-red: #d0021b;


//
// Scaffolding
//

$link-color: inherit;
$link-hover-color: $brand-primary;
$link-hover-decoration: none;


//
// Typography
//

$font-family-sans-serif: 'Open Sans', sans-serif;


//
// Iconography
//

$icon-font-path: "/build/fonts/";


//
// Buttons
//

$btn-font-weight: 600;

$btn-default-color: #333;
$btn-default-bg: #ccc;
$btn-default-border: #ccc;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $brand-primary;

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: $brand-success;

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: $brand-info;

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: $brand-warning;

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: $brand-danger;


//
// Grid system
//

$grid-gutter-width: 30px;


//
// Media queries breakpoints
//

$screen-lg-min: 1200px;


//
// Container sizes
//

$container-large-desktop: 1110px + $grid-gutter-width;


//
// Grid system
//

$grid-float-breakpoint: $screen-lg-min;


//
// Navbar
//

$navbar-height: 62px;
$navbar-default-color: #333;
$navbar-default-bg: white;


// Navbar links
$navbar-default-link-color: #333;
$navbar-default-link-hover-color: $brand-primary;
$navbar-default-link-active-color: $brand-primary;
$navbar-default-link-active-bg: transparent;
