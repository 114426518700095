.article-list {
  margin-left: -5px;
  margin-right: -5px;

  &__card-wrapper {
    padding: 0 5px 10px;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}
