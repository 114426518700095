.links-list {
  display: block;
  font-size: 0;
  position: relative;

  &__button {
    font: 11px Arial,pt_sans-rouble,sans-serif;
    color: #df4852;
    border: 1px solid #df4852;
    border-radius: 2px;
    text-decoration: none;
    min-width: 46px;
    padding-left: 7px;
    padding-right: 7px;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 4px;
    }

    &:hover {
      color: #fff;
      border-color: #df4852;
      background: #df4852;
    }
  }
}
