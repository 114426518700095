.gc-padding {
  padding: 0 15px;
}

.gc-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 28px;
  z-index: 1;
}
