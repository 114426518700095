.terms-conditions {
  padding-top: 80px;
  padding-bottom: 80px;

  &__title {
    margin: 0 0 50px;
    text-transform: uppercase;
    text-align: center;
  }
}
