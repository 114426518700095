.home-product-list {
  background-color: #fafafa;
  padding-top: 60px;
  padding-bottom: 100px;

  &__title {
    font-family: Kurale, sans-serif;
    margin-bottom: 60px;
  }

  @media (max-width: $screen-md-max) {
    padding-top: 40px;
    padding-bottom: 80px;

    &__title {
      margin-bottom: 40px;
    }
  }

  &__product-list {
    margin-left: -5px;
    margin-right: -5px;
  }

  &__product-card-wrapper {
    padding: 0 5px 10px;
  }

  .product-card {
    width: auto;
  }

  @media (max-width: $screen-md-max) {
    &__product-card-wrapper {
      flex: 1 0 percentage(1 / 3);
    }
  }

  @media (max-width: $screen-sm-max) {
    &__product-card-wrapper {
      flex: 1 0 50%;
    }
  }

  @media (max-width: $screen-xs-max) {
    &__product-card-wrapper {
      flex: 1 0 100%;
    }
  }
}
