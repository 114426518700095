$slick-arrow-color: #a8a8a8;
$slick-prev-character: $fa-var-chevron-left;
$slick-next-character: $fa-var-chevron-right;
$slick-font-family: "FontAwesome";
$slick-loader-path: "/img/";

$slick-dot-color: #cccccc;
$slick-dot-color-active: $brand-primary;
$slick-dot-character: $fa-var-circle;
$slick-dot-size: 10px;

@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";

.slick-slide {
  outline: none;
}

.slick-prev,
.slick-next {
  height: 28px;

  &:before {
    font-size: 30px;
  }
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

.slick-dots {
  li button:before {
    opacity: 1;
  }
}

@media (max-width: $screen-sm-max) {
  .slick-prev,
  .slick-next {
    height: 28px;

    &:before {
      padding: 2px 0;
      font-size: 24px;
    }
  }

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -30px;
  }
}

.slick-tucked-arrows {
  .slick-prev {
    left: 30px;
  }

  .slick-next {
    right: 30px;
  }
}
