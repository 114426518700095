$gift-card-blue: #8852ff;

.btn-gift-card-blue-lg {
  font-family: "Manrope", sans-serif;
  border-width: 0;
  font-weight: bold;

  @include button-variant(
    white,
    $gift-card-blue,
    $gift-card-blue
  );

  @include button-size(16px, 40px, 22px, 30px, 12px);
}

.btn-gift-card-blue-md {
  font-family: "Manrope", sans-serif;
  border-width: 0;
  font-weight: bold;

  @include button-variant(
    white,
    $gift-card-blue,
    $gift-card-blue
  );

  @include button-size(16px, 20px, 16px, 22px, 16px);
}
