.notifications-popup {
  display: none;
  position: absolute;
  z-index: $zindex-dropdown;
  top: 75px;
  right: -70px;
  background: white;
  border-radius: 6px;
  width: 300px;
  height: 400px;
  box-shadow: 0 2px 5px 1px rgba(black, .3);
  margin: 0;
  padding: 0;

  &.open {
    display: flex;
    flex-direction: column;
  }

  &__arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: -10px;
    margin-left: -10px;
    border: 10px solid transparent;
    border-top-width: 0;
    border-bottom-color: rgba(0, 0, 0, .25);
    right: 84px;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      top: 1px;
      margin-left: -9px;
      border: 9px solid transparent;
      border-top-width: 0;
      border-bottom-color: #fff;
    }
  }

  &__heading {
    @include clearfix;

    padding: 8px 12px;

    .btn {
      padding: 8px 12px;
      float: right;
    }
  }

  &__heading-text {
    color: black;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 7px;
  }

  &__notification-list {
    flex: 1;
    overflow: auto;
    background-color: #f7f7f7;
    border-radius: 0 0 6px 6px;
    font-size: 14px;

    .notification {
      @include clearfix;

      padding: 10px 8px;

      .title {
        font-weight: 600;
      }

      .message {
        margin-top: 10px;
      }

      .buttons {
        text-align: right;
        margin-top: 5px;
        float: left;

        .btn {
          padding: 4px 8px 3px;
        }
      }

      .date {
        color: $gray-light;
        font-size: 12px;
        margin-top: 10px;
        text-align: right;
      }

      &.unread {
        background-color: #f0f0f0;
      }
    }

    .notification + .notification {
      border-top: 1px solid #ccc;
    }
  }

  &__loading {
    padding-top: 120px;
    text-align: center;
  }

  @media (max-width: $grid-float-breakpoint - 1px) {
    &.open {
      display: none;
    }
  }
}
