.article-page {
  padding-top: 30px;
  padding-bottom: 50px;

  &__image {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 485px;
    border-radius: 3px 3px 0 0;
    margin-bottom: 15px;
  }

  &__title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 40px;
    font-weight: bold;
    line-height: 48px;
    padding: 17px 40px 23px 15px;
    background-color: hsla(0, 0%, 98%, .85);
  }

  &__content {
    padding: 15px 20px;
    background: white;
    font-size: 16px;
    text-align: justify;

    img {
      max-width: 100%;
      height: auto;

      @media (max-width: $screen-xs-max) {
        float: none;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__youtube-video {
    margin-bottom: 15px;

    @media (min-width: $screen-md-min) {
      float: right;
      width: 500px;
      margin-left: 15px;
    }
  }

  &__website-link {
    display: block;
    float: right;
    padding: 10px;
    margin-left: 30px;
    margin-bottom: 30px;
    background: rgba(black, .1);
    text-align: center;
    color: blue;
  }

  &__website-icon {
    display: block;
    margin: 15px 25px 10px;
    width: 50px;
    height: 50px;
  }
}
