.redpoint-download {
  padding: 100px 0 80px;
  background: #fafafa;

  &__inner {
    display: flex;

    @media (max-width: $screen-xs-max) {
      flex-wrap: wrap;
    }
  }

  &__details {
    flex: 1;
    padding-top: 80px;

    @media (max-width: $screen-sm-max) {
      padding-top: 40px;
    }

    @media (max-width: $screen-xs-max) {
      min-width: 100%;
      order: 1;
    }
  }

  &__phone {
    flex: 1;

    @media (max-width: $screen-xs-max) {
      min-width: 100%;
      order: 0;
      text-align: center;
    }
  }

  &__phone-img {
    max-width: 100%;
  }

  &__title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1.2;

    @media (max-width: $screen-md-max) {
      font-size: 36px;
    }
  }

  &__description {
    margin-top: 50px;
    font-size: 16px;
    max-width: 500px;
  }

  &__links {
    display: flex;
    margin-top: 20px;
  }

  &__buttons {
  }

  &__button {
    display: block;
    margin-bottom: 10px;
  }

  &__button-img {
    display: inline-block;
    width: auto;
    height: 46px;

    &:first-child {
      margin-right: 12px;
    }
  }

  &__qrcode {
    margin-top: -10px
  }

  @media (max-width: $screen-xs-max) {
    padding: 80px 0 60px;
  }
}
