.redpoint-heading {
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: $screen-md-max) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (max-width: $screen-sm-max) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__inner {
    background: url(/img/home/heading.jpg) no-repeat right bottom;

    @media (max-width: $screen-md-max) {
      background-size: 80%;
    }

    @media (max-width: $screen-sm-max) {
      background: none;
    }
  }

  &__text {
    @include clearfix;

    background: linear-gradient(90deg, rgba(white, 1) 0, rgba(white, 1) 80%, rgba(white, 0) 100%);
    max-width: 530px;
  }

  &__title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1.2;

    @media (max-width: $screen-md-max) {
      font-size: 36px;
    }
  }

  &__description {
    font-size: 16px;
    padding: 0 5px;
    margin: 25px -5px 0;

    @media (max-width: $screen-sm-max) {
      padding: 15px;
      margin: 25px -15px 0;
      border-radius: 0;
    }
  }

  &__question {
    font-weight: bold;
  }

  &__answer {
    margin-bottom: 1em;
  }

  &__label {
    margin-top: 1em;
    font-weight: bold;
    font-size: 16px;
  }

  &__buttons {
    margin-top: 20px;
  }

  &__button {
    display: inline-block;
    margin-bottom: 10px;
  }

  &__button-img {
    display: inline-block;
    width: auto;
    height: 46px;

    &:first-child {
      margin-right: 12px;
    }
  }
}
