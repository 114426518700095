.home-partners {
  display: none;

  &__left,
  &__right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
  }

  &__left {
    left: 0;
    background: linear-gradient(90deg, rgba(white, 1) 0%, rgba(white, 1) 30%, rgba(white, 0) 100%);
  }

  &__right {
    right: 0;
    background: linear-gradient(90deg, rgba(white, 0) 0%, rgba(white, 1) 70%, rgba(white, 1) 100%);
  }

  &__wrapper {
    position: relative;
    padding: 20px 0;
  }

  &.slick-initialized {
    display: block;
  }

  &__item {
    padding: 0 10px;
  }

  &__item-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 8px;
    border: solid 1px #e1e1e1;
  }

  &__img {
    display: block;
  }
}
