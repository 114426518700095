.minii-mashin {
  padding-bottom: 30px;

  &__header {
    background: url(/img/petrovis/medee-header-v2.jpg) no-repeat center;
    background-size: auto 100%;
  }

  &__header-inner {
    padding-top: 25.14%;
  }
}
