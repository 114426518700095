.footer {
  border-top: 1px solid rgba(black, .1);

  &__main {
    display: flex;
    flex-wrap: wrap;
    padding-top: 45px;
    padding-bottom: 50px;
  }

  &__download {
    padding-top: 15px;
    flex: 1;
  }

  &__qrcode {
    padding-top: 15px;
    flex: 1;
  }

  &__logo-img {
    display: block;
    height: 48px;
    margin-bottom: 25px;
  }

  &__button {
    display: inline-block;
    margin-bottom: 10px;
  }

  &__button-img {
    display: inline-block;
    width: auto;
    height: 46px;

    &:first-child {
      margin-right: 12px;
    }
  }

  &__links {
    position: relative;
    padding-top: 15px;
    min-width: 250px;
  }

  &__link-line {
    margin-bottom: 22px;
  }

  &__link {
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__social {
    position: absolute;
    top: 15px;
    right: 0;
  }

  &__social-icon {
    display: block;
    width: 40px;
    height: 40px;
    background-color: #7F7C7A;
    margin-right: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border-radius: 4px;
    text-align: center;
    color: white;

    > i {
      margin-top: 12px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__line {
    background-color: rgba(black, .1);;
    height: 1px;
  }

  &__copyright {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    text-align: right;
  }

  @media (max-width: $screen-xs-max) {
    &__download {
      margin-top: 30px;
      order: 1;
    }

    &__links {
      width: 100%;
      order: 0;
    }
  }
}
