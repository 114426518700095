.delete-account-page {
  background-color: white;

  &__heading {
    font-size: 32px;
    margin-bottom: 1.5em;
    text-align: center;
  }

  &__form,
  &__inner {
    max-width: 300px;
    margin: 0 auto;
  }

  &__buttons {
    margin-top: 50px;
  }

  &__help-text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 2em;
  }
}
