.home-banner {
  background: #0693c8 url(/img/home/banner-v5.jpg) no-repeat center;
  min-height: 360px;

  @media (max-width: $screen-sm-max) {
    background-size: (1920px / 1.542);
    min-height: (360px / 1.542);
  }

  @media (max-width: $screen-xs-max) {
    background-size: (1920px / 2.2);
    min-height: (360px / 2.2);
  }
}
