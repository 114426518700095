.product-page {
  background-color: white;
  padding-bottom: 60px;

  &__images {
    padding-top: 2px;
    background-color: #262626;
  }

  &__slider-wrapper {
    padding: 0 90px;
  }

  &__slider {
    height: 480px;

    .slide {
      background: center center / contain no-repeat;
      height: 480px;
    }

    .slick-prev {
      left: -65px;
    }

    .slick-next {
      right: -65px;
    }
  }

  &__thumbnail-list {
    padding-top: 10px;
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__thumbnail {
    background: center center / cover no-repeat;
    height: 80px;
    border-radius: 5px;
    margin: 0 5px 10px;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    &.active::after {
      content: "";
      display: block;
      position: absolute;
      border: 3px solid $brand-primary;
      border-radius: 5px;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    > img {
      display: block;
      height: 100%;
      width: auto;
    }
  }

  @media (max-width: $screen-sm-max) {
    &__images {
      padding-top: 2px;
      background-color: #262626;

      &__slider-wrapper {
        padding: 0 45px;
      }

      &__slider {
        height: 320px;

        .slide {
          height: 320px;
        }

        .slick-prev {
          left: -35px;
        }

        .slick-next {
          right: -35px;
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    &__images {
      padding-top: 2px;
      background-color: #262626;

      &__slider-wrapper {
        padding: 0;
      }

      &__slider {
        max-height: 320px;

        .slide {
          max-height: 320px;
        }

        .slick-prev,
        .slick-next {
          display: none !important;
        }
      }
    }
  }

  &__description-text {
    font-size: 16px;
    line-height: 1.7;

    a {
      color: $brand-primary;
    }
  }

  &__details {
    padding-top: 30px;
    padding-bottom: 50px;

    h1 {
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 24px;
    }
  }

  &__details-line {
    color: #666666;
    font-size: 18px;

    .dot {
      margin: 0 1em;
    }
  }

  &__old-price {
    text-decoration: line-through;
  }

  &__price {
    padding-top: 20px;

    &__redpoints {
      margin-bottom: 15px;
      text-align: center;
      font-size: 16px;
    }

    &__extra-line {
      margin-bottom: 10px;
    }
  }

  &__button {
    position: relative;
    font-size: 14px;

    > i {
      display: block;
      position: absolute;
      left: 25px;
      top: 50%;
      margin-top: -8px;
      font-size: 16px;
    }
  }

  &__bottom-controls {
    padding: 10px 0 20px;
  }
}
