.redpoint-sign {
  font-weight: 600;

  &::before {
    content: "";
    display: inline-block;
    background: url(/img/small-red@2x.png) center center / cover no-repeat;
    height: .85em;
    width: .85em;
    margin-left: .3em;
    margin-right: .05em;
    vertical-align: baseline;
    margin-bottom: -1px;
  }

  &::after {
    content: "POINT";
    display: inline;
    font-size: 70%;
    letter-spacing: -.03em;
  }
}
