.gc-contact {
  &__dialog {
    font-family: "Manrope", sans-serif;
    width: auto;
    max-width: 1140px;
  }

  &__body {
    display: flex;
    padding: 10px;
    gap: 10px;
  }

  &__address {
    background: #332F3C url("/img/gift-card/contact-bg.png") right bottom no-repeat;
    border-radius: 10px;
    padding: 40px;
    flex: 5;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 144px;
  }

  &__address-row {
    display: flex;
    margin-bottom: 50px;
    gap: 25px;
    align-items: flex-start;
    max-width: 337px;
  }

  &__address-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  &__address-text-partner {
    font-size: 14px;
    opacity: .8;
    margin-top: 10px;
  }

  &__form {
    flex: 7;
    padding: 40px;
  }

  &__label {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }

  &__form-group {
    margin-bottom: 45px;
  }

  &__radio-group-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 15px;
  }

  &__radio-group {
    margin-bottom: 35px;
    line-height: 30px;

    .radio-inline {
      margin-left: 0;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__radio-group .radio-inline {
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    &__body {
      flex-direction: column;
    }

    &__title {
      margin-bottom: 44px;
    }

    &__address {
      padding: 20px;
    }

    &__address-row:last-child {
      margin-bottom: 20px;
    }

    &__form {
      padding: 5px;
    }

    &__form-group {
      margin-bottom: 15px;
    }

    &__radio-group-label {
      margin-bottom: 0;
    }

    &__radio-group {
      margin-bottom: 15px;
    }

    &__address {
      margin-bottom: 20px;
    }
  }
}
