.payment-errors {
  margin: 50px auto 140px;
  max-width: 500px;
}

#transaction-result-page {
  padding-top: 50px;
  padding-bottom: 140px;
  background-color: white;

  .inner-content {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  .status-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 25px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .message {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .btn-primary {
    min-width: 130px;
  }
}
