.navbar {
  border: none;
  margin-bottom: 0;

  .navbar-brand {
    line-height: $navbar-height !important;
    padding: 0 $navbar-padding-horizontal;

    .logo {
      display: inline-block;
      height: 44px;
      width: 146px;
      margin-right: 25px;
      vertical-align: middle;
    }
  }

  .navbar-right {
    margin-right: 0;
  }

  &__shadow {
    height: 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      height: 1px;
      margin-top: -1px;
      box-shadow: 0 0 3px 0 rgba(black, .3);
    }
  }

  &__main-menu > li {
    > a {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    > a:not([href]) {
      color: #999999;
    }

    .navbar__magazine-icon {
      background-image: url("/img/magazine/icon@2x.png");
      background-size: contain;
      width: 44px;
      height: 14px;
      margin-bottom: 2px;
    }

    > a:hover,
    > a:focus,
    &.active {
      .navbar__magazine-icon {
        background-image: url("/img/magazine/icon-active@2x.png");
      }
    }

    @media (min-width: $grid-float-breakpoint) {
      padding-left: 15px;
      padding-right: 15px;

      > a {
        display: flex;
        align-items: center;
        padding: 5px 0 0;
        border-bottom: 4px solid white;
        height: $navbar-height;
      }

      &.active > a {
        border-bottom: 4px solid $brand-primary;
      }
    }
  }

  &__login-buttons > li {
    > a {
      display: flex;
      align-items: center;
      height: 44px;
      border-radius: $border-radius-base;
      border: 2px solid $brand-primary;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      padding: 0 27px 0 16px;

      > i {
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .navbar__login-buttons__login {
      color: $brand-primary !important;
    }

    .navbar__login-buttons__register {
      background-color: $brand-primary !important;
      color: white !important;
    }

    @media (max-width: $screen-md-max) {
      & + li {
        padding-top: 10px;
      }

      .navbar__login-buttons__login,
      .navbar__login-buttons__register {
        margin: 0 15px;
        width: 170px;
      }
    }

    @media (min-width: $screen-lg-min) {
      padding-top: 12px;
      padding-bottom: 11px;

      .navbar__login-buttons__register {
        margin-left: 12px;
      }
    }
  }

  &__notifications {
    font-size: 20px;
    position: relative;

    i {
      color: #75787d;
      position: relative;
    }
  }

  &__notifications-count {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $brand-primary;
    top: -8px;
    right: -12px;
    color: white;
    font-size: 10px;
    text-align: center;
    line-height: 20px;
  }

  &__notifications-text {
    font-size: 13px;
    color: #888;
    margin-left: 10px;
  }

  &__spacer {
    height: $navbar-height;
  }

  @media (min-width: $screen-lg-min) {
    &__notifications > a {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
