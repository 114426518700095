.popup-content {
  &__close {
    display: none;
    background-color: #282828;
    color: white;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
  }

  &.is-active {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: $zindex-modal;

    .popup-content__close {
      display: block;
    }

    .popup-content__body {
      flex: 1;
    }

    .popup-content__video {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}
