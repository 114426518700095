.check-balance-page {
  background-color: white;

  &__heading {
    font-size: 28px;
    margin-bottom: 1.5em;
    text-align: center;
    text-transform: uppercase;
  }

  &__form,
  &__inner {
    max-width: 300px;
    margin: 0 auto;
  }

  &__balance {
    text-align: center;
  }

  &__points {
    margin-top: 1em;
    font-size: 18px;
  }

  &__buttons {
    margin-top: 50px;
  }

  &__help-text {
    font-size: 12px;
    text-align: center;
  }
}
