.home-point-tools {
  display: flex;
  flex-wrap: wrap;
  margin-top: 45px;
  margin-left: -10px;
  margin-right: -10px;
  max-width: 400px;

  &__button {
    display: block;
    width: 150px;
    height: 75px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(219, 55, 61, 0.12);
    padding: 15px 17px;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  &__icon {
    display: block;
    margin-bottom: 5px;
    width: auto;
    height: 20px;
  }

  @media (min-width: $screen-lg-min) {
    margin-top: 45px;
  }
}
