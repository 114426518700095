.gc-organization {
  &__modal.in {
    display: flex !important;

    .modal-dialog {
      min-width: 300px;
    }
  }

  &__dialog {
    font-family: "Manrope", sans-serif;
    width: auto;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 24px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: #332E3D;
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #423262;
  }

  &__cell {
    &--bold {
      font-weight: 600;
    }

    &--photo {
      min-height: 134px;
      padding: 0;
    }

    &--photo-img {
      max-width: 169px;
      max-height: 169px;
    }

    &--alt-bg {
      background: #F8F9FA;
    }
  }

  &__table-responsive {
    border: none;
  }

  &__table {
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;

    th {
      min-width: 160px;
      max-width: 160px;
    }

    td {
      min-width: 210px;
      max-width: 210px;
    }

    th,
    td {
      padding: 12px 20px;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: -0.5px;
      color: #332E3D;
      //border: 1px solid #D9D9D9;
    }

    th,
    td {
      border-right: 1px solid #D9D9D9;
      border-bottom: 1px solid #D9D9D9;
    }

    th:first-child,
    td:first-child {
      border-left: 1px solid #D9D9D9;
    }

    tr:first-child th,
    tr:first-child td {
      border-top: solid 1px #D9D9D9;
    }

    tr:first-child th:first-child {
      border-top-left-radius: 12px;
    }

    tr:first-child td:last-child {
      border-top-right-radius: 12px;
    }

    tr:last-child th:first-child {
      border-bottom-left-radius: 12px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 12px;
    }
  }
}
