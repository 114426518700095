.page-content {
  @include clearfix;

  &.page-content--white-backdrop {
    background-color: white;
  }

  &__inner {
    max-width: 840px;
    margin: 30px auto;
  }
}
