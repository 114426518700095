.not-enough-funds {
  margin-top: 15px;
  background-color: #4c4c4c;
  padding: 15px;
  position: relative;
  border-radius: 5px 5px 8px 8px;

  &__message {
    font-size: 14px;
    color: white;
    margin: 10px 0 15px;
    text-align: center;
  }

  &__points {
    text-align: center;
    font-size: 20px;
    color: white;
  }

  &__own-points {
    font-weight: bold;
  }

  &__slash {
    margin-left: .1em;
    margin-right: .1em;
  }

  &__buttons {
    display: flex;
    margin-top: 15px;

    button {
      @include reset-input;
      @include button-variant($text-color, white, white);

      display: block;
      width: 100%;
      height: 64px;
      font-size: 12px;
      border-radius: 8px;
      text-align: left;
      padding: 0 10px;
      margin-top: 10px;

      img {
        width: 30px;
        height: 30px;
        vertical-align: baseline;
        margin-right: 10px;
        margin-bottom: -2px;
      }

      span {
        display: inline-block;
        text-align: left;
      }
    }

    button:first-child {
      margin-right: 5px;
    }

    button:last-child {
      margin-left: 5px;
    }
  }

  &::after {
    bottom: 100%;
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 12px solid transparent;
    border-bottom-color: #4c4c4c;
    margin-left: -12px;
  }
}
