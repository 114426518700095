@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline;
  }
}

.reset-list {
  @include reset-list;
}

@mixin reset-link {
  color: inherit;
  text-decoration: inherit;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: inherit;
  }
}

.reset-link {
  @include reset-link;
}

@mixin reset-input {
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  outline: none;
  line-height: inherit;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.full-height {
  height: 100%;
}

a.neutral {
  color: inherit;
  text-decoration: inherit;
}
