.product-card {
  display: block;
  position: relative;
  border: 1px solid #d1d1d1;
  background: #fff;
  transition: box-shadow .2s cubic-bezier(.55,0,.1,1);
  text-align: left;

  &__photo {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: relative;
      padding-top: 100%;
    }
  }

  &__photo-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__info {
    padding: 15px;
  }

  &__description {
    font-size: 14px;
    line-height: 25px;
    color: #454545;
    height: 50px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;

    &::after {
      content: '';
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%);
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 25%;
      height: 25px;
    }
  }

  &__price-row {
    font-size: 13px;
    font-weight: 400;
  }

  &__sale-tag {
    position: absolute;
    background: url(/img/sale-tag.png) no-repeat;
    background-size: contain;
    top: -8px;
    right: -8px;
    width: 74px;
    height: 74px;
  }

  &__old-price {
    text-decoration: line-through;
  }

  &__price {
    font-weight: bold;
    color: $brand-primary;
  }

  &:hover {
    box-shadow: 0 4px 8px #b0aeab;
  }
}
