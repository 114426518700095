.confirmation {
  padding-top: 80px;
  padding-bottom: 80px;

  &__content {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  &__status-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__message {
    margin-top: 15px;
    margin-bottom: 30px;
  }
}
