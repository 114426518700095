.home-more-services {
  background: #fafafa;
  padding: 90px 0 45px;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  &__service {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 50px;
    max-width: calc(25% - 20px);

    @media (max-width: $screen-md-max) {
      max-width: calc(33.33% - 20px);
    }

    @media (max-width: $screen-sm-max) {
      max-width: calc(50% - 20px);
    }

    @media (max-width: $screen-xs-max) {
      max-width: 100%;
    }
  }

  &__icon-wrapper {
    width: 82px;
  }

  &__icon {
    display: block;
    margin: 0 auto;
  }

  &__details {
    flex: 1;
    padding-left: 5px;
  }

  &__name {
    display: block;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &__description {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
}
