.bank-buy-points {
  padding-top: 50px;
  padding-bottom: 140px;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;

  &__heading {
    font-size: 24px;
    margin-bottom: 50px;
  }

  &__table {
    margin-bottom: 50px;
  }

  &__tip {
    margin-bottom: 30px;
  }

  &__qr-code-img {
    margin-bottom: 50px;
  }

  &__form {
    text-align: left;
  }
}
