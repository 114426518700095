.magazine-categories {
  margin-top: 28px;
  margin-bottom: 20px;
  position: relative;

  &__list {
    display: block;
    font-size: 0;
    position: relative;
  }

  &__category {
    font-size: 11px;
    color: #8D8D8D;
    border: 1px solid #8D8D8D;
    border-radius: 2px;
    text-decoration: none;
    min-width: 46px;
    padding-left: 7px;
    padding-right: 7px;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:hover,
    &.is-active {
      color: #fff;
      border-color: #df4852;
      background: #df4852;
    }
  }

  &__num-items {
    position: relative;
    font-size: 9px;
    top: -3px;
    margin-left: 2px;
  }
}
