.main-menu {
  box-shadow: 0 0 20px 0 rgba(black, .09);

  &__brand {
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 44px;
  }

  &__item {
    font-size: 16px !important;
    letter-spacing: 0.5px !important;
  }

  &__placeholder {
    height: $navbar-height;
  }
}
