.coming-soon {
  position: relative;

  &::before {
    content: 'Тун удахгүй';
    display: block;
    position: absolute;
    background: #d20001;
    border-radius: 16px;
    color: white;
    top: -10px;
    right: -10px;
    padding: 0 8px;
    opacity: .8;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }
}
