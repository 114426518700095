.gc-s1 {
  padding: 80px 0;

  &__content {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    align-items: center;
    gap: 80px;
  }

  &__text {
    flex: 29;
  }

  &__title {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 120%;
    color: #332E3D;
  }

  &__body {
    margin-top: 24px;
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #423262;
  }

  &__button {
    margin-top: 52px;
  }

  &__card {
    flex: 31;
    background: rgba(137, 80, 252, 0.4) url("/img/gift-card/gift-cards.png") no-repeat calc(50% - 60px) calc(50% + 30px);
    border-radius: 32px;
    padding: 24px;
    height: 696px;
  }

  &__card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 12px;
    height: 31px;
    border-bottom: 1px solid #D9D9D9;
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #423262;
  }

  &__card-spacer {
    width: 100%;
    padding-bottom: 85%;
  }

  @media (max-width: 991px) {
    padding: 15px 0;

    &__content {
      flex-direction: column;
      align-items: stretch;
      gap: 40px;
    }

    &__text {
      flex: auto;
      order: 1;
    }

    &__card {
      flex: auto;
      order: 0;
      height: auto;
      background-size: 110%;
      background-position: calc(50% - 10px) calc(50% + 30px);
    }
  }
}

.gc-s2 {
  margin-top: 80px;
  margin-bottom: 80px;

  &__content {
    margin: 0 auto;
    max-width: 1440px;
    background: #332F3C;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 60px;
    gap: 80px;
  }

  &__text {
    flex: 1;
  }

  &__body {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #F4F4F4;
    margin-top: 24px;
  }

  &__slogan {
    flex: 1;
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }

  &__blue-snippet {
    color: #9C6BFF;
  }

  @media (max-width: 991px) {
    &__content {
      padding: 30px;
      border-radius: 16px;
      flex-direction: column;
      align-items: stretch;
      gap: 60px;
    }

    &__slogan {
      font-size: 28px;
    }

    &__text {
      flex: auto;
      order: 1;
    }

    &__slogan {
      flex: auto;
      order: 0;
    }
  }
}

.gc-s3 {
  padding: 80px 0;

  &__content {
    margin: 0 auto;
    max-width: 1440px;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;
    max-width: 540px;
    margin: 0 auto;
  }

  &__title {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.2px;
    color: #161C2D;
  }

  &__subtitle {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1.625px;
    text-transform: uppercase;
    color: #F64B4B;
  }

  &__card-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    gap: 40px;
    margin-top: 80px;
  }

  &__card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px;
    gap: 24px;
    min-height: 300px;
    background: #F4F4F4;
    border-radius: 25px;
    min-width: 599px;
  }

  &__card-icon {
    display: block;
    width: 48px;
    height: 48px;
  }

  &__card-title {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #332E3D;
  }

  &__card-body {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.2px;
    color: #423262;
    mix-blend-mode: normal;
    opacity: 0.7;
    align-self: stretch;
    margin-top: -9px;
  }

  &__card-coming-soon {
    font-weight: 500;
    color: #8950FC;
  }

  @media (max-width: 1269px) {
    &__card-list {
      gap: 30px;
    }

    &__card {
      min-width: 449px;
      min-height: 350px;
    }
  }

  @media (max-width: 991px) {
    padding-top: 0;

    &__card-list {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
    }

    &__card {
      min-width: 316px;
    }
  }
}

.gc-s4 {
  padding: 80px 15px;
  background: #F4F4F4;

  &__content {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 80px;
  }

  &__title {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.2px;
    color: #161C2D;
  }

  &__organization-list {
    overflow: hidden;
  }

  &__organization-list-inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 0;
    margin: 0 -1px -1px;
  }

  &__organization {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    height: 96px;
  }

  &__organization-logo {
    max-width: 120px;
    max-height: 50px;

    &--1x1 {
      max-width: 90px;
      max-height: 70px;
    }

    &--3x2 {
      max-width: 105px;
      max-height: 70px;
    }

    &--2x1 {
      max-width: 110px;
      max-height: 65px;
    }

    &--vertical {
      max-width: 70px;
      max-height: 80px;
    }
  }

  &__organization:hover &__organization-logo,
  &__organization:active &__organization-logo,
  &__organization:focus &__organization-logo {
    transform: scale(1.1);
    transition: transform .5s ease-out;
  }
}

.gc-s5 {
  display: block;
  padding: 100px 15px;
  background: #332F3C url("/img/gift-card/redpoint-gift-card-v2.png") no-repeat calc(50% - 390px) center;
  min-height: 900px;

  &__content {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    justify-content: flex-end;
  }

  &__text {
    flex: 1;
    max-width: 489px;
  }

  &__title {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: #FFFFFF;
    margin-bottom: 32px;
  }

  &__body {
    padding-right: 37px;
  }


  &__row {
    position: relative;
    padding-left: 72px;
    min-height: 154px;
  }

  &__circle-outer {
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border: 1px solid rgba(#8950FC, .2);
    border-radius: 50%;
    padding: 5px;
  }

  &__circle {
    width: 36px;
    height: 36px;
    background: #8950FC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }

  &__step-name {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-bottom: 15px;
  }

  &__line {
    position: absolute;
    left: 24px;
    top: 56px;
    bottom: 8px;
    border-right: 1px dashed rgba(white, 0.3);
  }

  &__step-description {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #F4F4F4;
    opacity: 0.8;
  }

  &__buttons {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 12px;
    gap: 28px;
  }

  @media (max-width: 991px) {
    background-image: none;

    &__content {
      flex-direction: column;
      align-items: stretch;
    }

    &__text {
      max-width: 100%;
    }
  }
}
