//
// Buttons
// --------------------------------------------------


// Button sizes
// --------------------------------------------------

.btn {
  @include button-size(12px, $padding-base-horizontal, 12px, 16px, $btn-border-radius-base)
}

.btn-lg {
  @include button-size(14px, $padding-base-horizontal, 14px, 18px, $btn-border-radius-base)
}

.btn-sm {
  @include button-size(7px, $padding-base-horizontal, 12px, 16px, $btn-border-radius-base)
}

// Other mutations
// --------------------------------------------------

.modal .btn {
  @include button-size(15px, $padding-base-horizontal, 14px, 18px, $btn-border-radius-base)
}
