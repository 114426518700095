.contact-us {
  padding-top: 80px;
  padding-bottom: 80px;

  &__title {
    margin: 0 0 50px;
    text-transform: uppercase;
    text-align: center;
  }

  &__link {
    color: $text-color;
    text-decoration: none;
  }

  &__key:hover {
    text-decoration: underline;
  }

  &__section {
    font-size: 14px;
    max-width: 360px;
    margin: 0 auto 15px;

    @include clearfix;
  }

  &__img {
    float: left;
  }

  &__text {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    min-height: 65px;
    justify-content: center;
    text-align: center;
    line-height: 175%;
    padding: 0 20px;

  }

  @media (max-width: $screen-sm-max) {
    &__content {
      margin-top: 15px;
      border-top: 1px solid #eee;
      padding-top: 55px;
    }

    &__img {
      display: block;
      margin: 0 auto 10px;
      float: none;
    }

    &__text {
      margin-left: 0;
    }
  }
}
