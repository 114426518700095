.article-card {
  display: block;
  position: relative;
  border-radius: 3px;
  background: #fff;
  transition: box-shadow .2s cubic-bezier(.55,0,.1,1);
  text-align: left;

  &__image {
    border-radius: 3px 3px 0 0;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      position: relative;
      padding-top: 100%;
    }
  }

  &__image-img {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }

  &__mask {
    width: 100%;
    height: 192px;
    margin-top: -192px;
    position: relative;
    transition: all .2s cubic-bezier(.55,0,.1,1);
    background: linear-gradient(to bottom, transparent 0, rgba(39, 38, 42, .75) 100%) repeat-x;
  }

  &__info {
    padding: 15px 15px 30px;
  }

  &__title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__date {
    font-size: 12px;
    color: #929292;
  }

  &__snippet {
    margin-top: 14px;
    font-size: 13px;
  }

  &:hover {
    box-shadow: 0 4px 8px #b0aeab;
  }
}
