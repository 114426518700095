.red-points {
  font-weight: 700;
  color: #666666;

  i {
    display: inline-block;
    background: url(/img/small-red@2x.png) center center / cover no-repeat;
    height: .85em;
    width: .85em;
    margin-left: .4em;
    margin-right: .1em;
    vertical-align: baseline;
    margin-bottom: -1px;
  }

  .point-text {
    color: black;
    font-size: 80%;
  }
}

.red-text {
  color: $brand-primary;
}

.logout-message {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-weight: 600;
}
