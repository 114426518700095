#error-page {
  padding-top: 50px;
  padding-bottom: 140px;

  .heading-image {
    display: block;
    margin: 0 auto 30px;
  }

  h1 {
    text-align: center;
  }
}
